import { CiBarcode } from 'react-icons/ci'
import { BsCheck } from 'react-icons/bs'
import { FiPrinter } from 'react-icons/fi'
import { QRCodeCanvas } from 'qrcode.react';

import { useSWRConfig } from "swr"
import { FaPlus, FaTrash } from 'react-icons/fa'
import { api } from '../../api/api'
import { useContext } from 'react'
import { OrderContext } from '../../context/OrderContext'
import { Toast } from '../../utils/Toast'
import { MdEditSquare } from 'react-icons/md'
import { IoClose } from 'react-icons/io5'
import { GlobalContext } from '../../context/GlobalContext'
import { useCompany } from '../../hooks/useCompany';
import { sendPrint } from '../../reporting/print';



export function ModalTable ({ payload, clear }) {
  const { isAccess, openModalOrder } = useContext(GlobalContext)
  const { company } = useCompany()
  const { selectOrder } = useContext(OrderContext)
  const { mutate } = useSWRConfig()

  async function closeTable (id) {
    await api.post('/tables/close-table', { id })
    Toast.success()
    await mutate('list-tables')
    document.getElementById('modaltable-close').click()
  }

  async function openOrder (id) {
    await selectOrder(id)
    document.getElementById('modaltable-close').click()
    openModalOrder()
    await mutate('/product-order/products/not-seen')
  }

  async function printOrder (id) {
    await sendPrint('order', {}, id)
  }

  async function addCommand (tableId, code) {
    if (!tableId || !code) {
      Toast.warning('Para adicionar uma comanda é necessário o código')
      return
    }

    const response = await api.post('/commands', {
      idTable: tableId, code
    })

    Toast.success()
    await Promise.all([
      openOrder(response.data.idOrder),
      mutate('list-tables'),
      mutate('/orders')
    ])
  }

  async function removerCommand (id) {
    if (!isAccess('mesas', 'deletar_comanda')) {
      return Toast.warning('Você não tem permissão para deletar comanda.')
    }

    await api.delete(`/commands/${id}`)
    Toast.success('Commanda removida com sucesso')
    await mutate('list-tables')
    document.getElementById('modaltable-close').click()
  }

  async function updateCodeCommand (idCommand, code) {
    await api.put('/commands/' + idCommand + '/' + code)
    Toast.success("Comanda movida com sucesso")
    await mutate('list-tables')
    await mutate('/orders')
  }

  function getUrlCardapio (codeTable = '') {
    if (company.domain) {
      return `https://${company.domain}?codeTable=${codeTable}`
    } else {
      return `${process.env.REACT_APP_CARDAPIO}/${company.link}?codeTable=${codeTable}`
    }
  }

  return (
    <div className="modal fade" id="ModalTable" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ModalTableLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalTableLabel">{payload?.commands?.length > 1 ? 'Comandas' : 'Pedido'}</h5>
            <button onClick={() => clear()} type="button" id="modaltable-close" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                {payload?.commands?.length === 0 && (
                  <button className="btn btn btn-secondary" onClick={async () => {
                    if (!isAccess('mesas', 'adicionar_comanda')) {
                      return Toast.warning('Você não tem permissão para criar um pedido de mesa')
                    }

                    const add = await Toast.confirm('Adicionar pedido?', 'Não', 'Sim')
                    if (payload.id && add)
                      addCommand(payload.id, payload.id)
                  }}>
                    <FaPlus /> Adicionar Pedido
                  </button>
                )}

                <button className="ms-3 btn btn btn-outline-secondary" onClick={async () => {
                  if (!isAccess('mesas', 'adicionar_comanda')) {
                    return Toast.warning('Você não tem permissão para criar um pedido de mesa')
                  }

                  const result = await Toast.getValue('Qual código de comanda?', undefined, undefined, document.getElementById('ModalTable'))

                  if (payload.id && result)
                    addCommand(payload.id, result)
                }}>
                  <FaPlus /> Adicionar comanda
                </button>
                {isAccess() !== 'garcom' && (
                  <>
                    {Boolean(payload?.available) === false && (
                      <button className="ms-3 btn btn btn-danger" onClick={async () => {
                        if (!isAccess('mesas', 'fechar_mesa')) {
                          return Toast.warning('Você não tem permissão para fechar mesa')
                        }

                        const confirm = await Toast.confirm("Deseja realmente fechar a mesa?", "Não", "Sim")
                        if (confirm) {
                          closeTable(payload.id)
                        }
                      }}>
                        Fechar mesa
                      </button>
                    )}
                  </>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <QRCodeCanvas id='canva-qrcode' size={512} value={getUrlCardapio(payload?.codeQrcode)} />
              </div>
              <button onClick={() => {
                const link = document.createElement('a');
                link.download = `mesa-${payload.name}.png`;
                link.href = document.getElementById('canva-qrcode').toDataURL()
                link.click();
              }} className='btn btn-sm btn-outline-primary'>Baixar QRCode</button>
            </div>

            <ul className="list-group" style={{ height: 400, overflow: 'auto' }}>
              {payload?.commands?.map((command) => {
                return (
                  <li key={command.id} className="pointer list-group-item"
                    onClick={() => openOrder(command.idOrder)}
                    style={{}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: 30, marginTop: 10 }}>
                      {['finalizado', 'cancelado'].includes(command.status) && (
                        command.status === 'cancelado' ? <IoClose color='red' size={40} /> : <BsCheck color="green" className="" size={40} />
                      )}
                      {!['finalizado', 'cancelado'].includes(command.status) && <CiBarcode className="" size={30} />}
                      {command.code.length === 36 && (<h2>-</h2>)}
                      {command.code.length < 36 && (<h2>{command.code}</h2>)}
                    </div>

                    <div style={{ marginTop: 10, display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }}>
                      {command.code.length === 36 && (
                        <button style={{ height: 50 }} className="btn btn-sm border" onClick={async (e) => {
                          e.stopPropagation()

                          if (!isAccess('mesas', 'alterar_codigo_comanda')) {
                            return Toast.warning('Você não tem permissão para altear código de comanda')
                          }

                          document.getElementById('modaltable-close').click()
                          const codeText = await Toast.getValue('Digite o código da comanda')
                          if (codeText) {
                            updateCodeCommand(command.id, codeText)
                          }
                        }}>
                          Alterar código <MdEditSquare size={15} />
                        </button>
                      )}
                      {/* {!['finalizado', 'cancelado'].includes(command.status) && (
                        <button style={{ height: 50 }} className="btn btn-sm border" onClick={(e) => {
                          e.stopPropagation()
                          // transferCommand(command.id, command.idTable)
                        }}>
                          Tranferir comanda <BiTransferAlt size={15} />
                        </button>
                      )} */}

                      <button style={{ height: 50 }} onClick={(e) => {
                        e.stopPropagation()
                        printOrder(command.idOrder)
                      }} className="btn btn-sm border" >
                        Imprimir comanda <FiPrinter />
                      </button>

                      {(!['finalizado', 'cancelado'].includes(command.status) && isAccess() !== 'garcom') && (
                        <button style={{ width: 100, height: 50 }} className="btn btn-sm border" onClick={(e) => {
                          e.stopPropagation()
                          removerCommand(command.id)
                        }}>
                          Deletar comanda <FaTrash />
                        </button>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}