/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavBar } from "../../components/NavBar";
import { FaCashRegister } from "react-icons/fa";
import { maskCurrency } from "../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import useSWR from "swr";
import { api } from "../../api/api";
import { ModalSafe } from "../../components/Modals/Safe";
import { useDispatch, useSelector } from "react-redux";
import { SET_SAFE, SET_SAFE_DATA } from "../../stores/reducers/SafeReducers";
import { SELECT_ORDER } from "../../stores/reducers/OrderReducers";
import { OpenSafe } from "../../components/Modals/OpenSafe";
import { GlobalContext } from "../../context/GlobalContext";
import _ from "lodash";
import { ModalCloseSafe } from "./components/ModalCloseSafe";

export function SafePage () {
  const { isAccess, openModalOrder } = useContext(GlobalContext)
  const safes = useSelector(state => state.safe.data)

  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState({
    key: '',
    value: ''
  })
  const [viewRegisters, setViewRegisters] = useState(false)
  const dispatch = useDispatch()

  const [movimentValues, setMovimentValues] = useState({
    entrada: 0,
    saida: 0,
    sangria: 0,
    total: 0
  })

  const { isLoading } = useSWR('/safe', async () => {
    const response = await api.get(`/safe`)

    dispatch(SET_SAFE_DATA(response.data))
    return response.data
  })

  async function openOrder (id) {
    const response = await api.get(`/orders/${id}`)
    dispatch(SELECT_ORDER(response.data))
    openModalOrder()
  }

  useEffect(() => {
    setMovimentValues(safes.reduce((prev, current) => {
      if (current.type === 'entrada') {
        return {
          ...prev,
          entrada: prev.entrada += current.pricing,
          total: prev.total + current.pricing
        }
      }

      if (current.type === 'saida') {
        return {
          ...prev,
          saida: prev.saida += current.pricing,
          total: prev.total - current.pricing
        }
      }

      if (current.type === 'sangria') {
        return {
          ...prev,
          sangria: prev.sangria += current.pricing,
        }
      }

      return prev
    }, {
      entrada: 0,
      saida: 0,
      sangria: 0,
      total: 0
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safes])

  async function verifiySafeIsOpen () {
    try {
      const res = await api.get('/safe/is/open')

      if (res?.data) {
        setIsOpen(true)
      } else {
        dispatch(SET_SAFE_DATA([]))
        setIsOpen(false)
      }
    } catch (error) {
      dispatch(SET_SAFE_DATA([]))
      setIsOpen(false)
    }
  }

  useEffect(() => {
    verifiySafeIsOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const safesFiltered = safes.filter(e => {
    if (filter.key) {
      if (e[filter.key] === filter.value) {
        return true
      } else {
        return false
      }
    }

    return true
  })

  const card = safesFiltered.filter(e => e.typePayment === 'card').reduce((prev, current) => prev + current.pricing, 0)
  const money = safesFiltered.filter(e => e.typePayment === 'money').reduce((prev, current) => prev + current.pricing, 0)
  const pix = safesFiltered.filter(e => e.typePayment === 'pix').reduce((prev, current) => prev + current.pricing, 0)
  const ifood = safesFiltered.filter(e => e.typePayment === 'ifood').reduce((prev, current) => prev + current.pricing, 0)


  return (
    <NavBar>
      <ModalSafe />
      <OpenSafe reload={() => verifiySafeIsOpen()} viewRegisters={viewRegisters} />
      <ModalCloseSafe reload={() => verifiySafeIsOpen()} />
      <div className="container-fluid w1">
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>  <FaCashRegister className="mb-1" color='white' size={22} /> Caixa</p>
          <div>
            {!isOpen && (
              <button className="ms-2 btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#modalIsOpenSafe" onClick={() => setViewRegisters(false)}>
                <FaCashRegister className="mb-1" color='white' /> Abrir caixa
              </button>
            )}

            <button className="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modalIsOpenSafe" onClick={() => setViewRegisters(true)}>
              <FaCashRegister className="mb-1" color='white' /> Registros do Caixa
            </button>

            {isOpen && (
              <>
                {(isAccess('caixa', 'open_safe') || isAccess('caixa', 'close_safe')) && (
                  <button className="ms-2 btn btn-sm bg-white text-dark" data-bs-toggle="modal" data-bs-target="#modalCloseSafe">
                    <FaCashRegister className="mb-1" color='black' /> Fechar Caixa
                  </button>
                )}
              </>
            )}

          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', marginBottom: 10 }}>
          {isAccess('caixa', 'create') && (
            <div>
              <button className="btn btn-sm bg-dark text-white btn-circle" data-bs-toggle="modal" data-bs-target="#modalSafe">
                <i className="fas fa-plus"></i>
              </button>
            </div>
          )}
        </div>

        {isLoading && (
          <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', height: '80vh' }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {!isLoading && (
          <>
            <div className="row mb-4">
              <div className="col-sm-4">
                <div className="border rounded pointer p-2 shadow-sm border-success" style={{ border: '1px sli', display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
                  <p style={{ fontWeight: 'bold' }}>Entradas</p>
                  <p className="mt-2">{maskCurrency(movimentValues.entrada)}</p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="border rounded pointer p-2 shadow-sm border-danger" style={{ border: '1px sli', display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
                  <p style={{ fontWeight: 'bold' }}>Saidas</p>
                  <p className="mt-2">{maskCurrency(movimentValues.saida)}</p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="border rounded pointer p-2 shadow-sm border-warning" style={{ border: '1px sli', display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
                  <p style={{ fontWeight: 'bold' }}>Total</p>
                  <p className="mt-2">{maskCurrency(movimentValues.total)}</p>
                </div>
              </div>
            </div>

            <div>
              <h4 style={{ backgroundColor: '#364750', padding: 5, borderRadius: 3, color: 'white' }}>Pagamentos por tipo</h4>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <p>Cartão</p>
                <p style={{ fontSize: '1.2rem' }}>{maskCurrency(card)}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <p>Dinheiro</p>
                <p style={{ fontSize: '1.2rem' }}>{maskCurrency(money)}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <p>PIX</p>
                <p style={{ fontSize: '1.2rem' }}>{maskCurrency(pix)}</p>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <p>Ifood (Apenas pagamentos online)</p>
                <p style={{ fontSize: '1.2rem' }}>{maskCurrency(ifood)}</p>
              </div>
            </div>
            <hr />

            <div style={{ display: 'flex', gap: 10, marginBottom: 10, float: 'right' }}>
              <span onClick={() => setFilter({ key: '', value: '' })} style={{ fontSize: '0.9rem' }} className="pointer badge bg-primary">Todos</span>
              <span onClick={() => setFilter({ key: 'typePayment', value: 'money' })} style={{ fontSize: '0.9rem' }} className="pointer badge bg-secondary">Dinheiro</span>
              <span onClick={() => setFilter({ key: 'typePayment', value: 'card' })} style={{ fontSize: '0.9rem' }} className="pointer badge bg-success">Cartão</span>
              <span onClick={() => setFilter({ key: 'typePayment', value: 'pix' })} style={{ fontSize: '0.9rem' }} className="pointer badge bg-danger">Pix</span>
              <span onClick={() => setFilter({ key: 'typePayment', value: 'ifood' })} style={{ fontSize: '0.9rem' }} className="pointer badge bg-dark">Ifood</span>
            </div>

            <table className="table table-borderless table-striped table-sm">
              <thead className="text-white rounded" style={{ border: 'none', backgroundColor: "rgb(58, 71, 80)" }}>
                <tr>
                  <th className="text-center">Tipo</th>
                  <th className="text-center">Data</th>
                  <th className="text-center">Valor</th>
                  <th className="text-center">Forma de Pagamento</th>
                  <th className="text-center">Descrição</th>
                  <th className="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                {_.orderBy(safesFiltered, 'createdAt', 'desc').map(moviment => {
                  const typePayments = {
                    card: 'Cartão',
                    money: 'Dinheiro',
                    pix: 'PIX',
                    ifood: 'Ifood',
                  }

                  return (
                    <tr key={moviment.id} className="p-2 text-center">
                      {moviment.type === 'entrada' && <td style={{ fontWeight: "bold" }} className="text-success">{String(moviment.type).toUpperCase()}</td>}
                      {moviment.type === 'saida' && <td style={{ fontWeight: "bold" }} className="text-danger">{String(moviment.type).toUpperCase()}</td>}
                      {moviment.type === 'sangria' && <td style={{ fontWeight: "bold" }} className="text-warning">{String(moviment.type).toUpperCase()}</td>}
                      <td>{DateTime.fromISO(moviment.date.substr(0, 10)).toFormat('dd/MM/yyyy')}</td>
                      <td>{maskCurrency(moviment.pricing)}</td>
                      <td>{typePayments[moviment.typePayment]}</td>
                      <td>{moviment?.description?.includes('Ifood') ? 'Pedido Ifood' : moviment.description}</td>
                      <td>
                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg data-v-7b9cd38c="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="gear fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi-gear-fill contentActions b-icon bi"><g data-v-7b9cd38c=""><path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"></path></g></svg>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                              {moviment.orderId && <li><a className="dropdown-item" rel="noreferrer" onClick={() => openOrder(moviment.orderId)}>Pedido</a></li>}
                              <li>
                                <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalSafe" onClick={(e) => dispatch(SET_SAFE({
                                  id: moviment.id,
                                  type: moviment.type,
                                  pricing: moviment.pricing,
                                  orderId: moviment.orderId,
                                  description: moviment.description,
                                  date: DateTime.fromISO(moviment.date).toFormat('yyyy-MM-dd'),
                                  typePayment: moviment.typePayment,
                                }))}>Detalhes</a>
                              </li>
                            </li>
                          </ul>
                        </div>

                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}

      </div>
    </NavBar>
  )
}