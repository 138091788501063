import { MdOutlinePayments, MdRemoveCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { mascaraMoeda, maskCurrency } from "../../../../utils/utils";
import { useContext, useEffect, useState } from "react";
import { PaymentMethod } from "../../../../utils/PaymentMethods";
import { Toast } from "../../../../utils/Toast";
import { UPDATE_SELECT_ORDER } from "../../../../stores/reducers/OrderReducers";
import { v4 } from "uuid";
import { OrderContext } from "../../../../context/OrderContext";
import { GlobalContext } from "../../../../context/GlobalContext";
import { api } from "../../../../api/api";

export function Payments ({ order, closeOrCancelOrder, inputRef }) {
  const { isAccess } = useContext(GlobalContext)
  const [cards, setCards] = useState([])
  const dispatch = useDispatch()
  const { updated } = useContext(OrderContext)

  const [state, setState] = useState({
    id: v4(),
    type: 'money',
    idCard: null,
    pricing: 0,
    pricingPayment: 0,
    pricingChange: 0,
  })

  function onChange (key, value) {
    setState({
      ...state,
      [key]: value
    })
  }

  let valuePricing = order?.payments?.reduce((prev, current) => prev + current.pricing, 0)
  let pricingRestante = order.total - valuePricing

  useEffect(() => {
    setState({
      ...state,
      pricing: valuePricing > 0 ? (order.total - valuePricing) : order.total
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.total, valuePricing])

  useEffect(() => {
    if (cards.length === 0) {
      api.get('/cards').then((res) => {
        setCards(res.data.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>
        <div className="container row mt-3 div-payment" id="div-payment">
          <div className="col-sm-12">
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
              <MdOutlinePayments size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
              <p style={{ fontWeight: 600 }}>Pagamentos</p>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <label>Forma de pagamento</label>
                <select ref={inputRef} value={state.type} onChange={(e) => onChange('type', e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
                  <option value="money">Dinheiro</option>
                  <option value="card">Cartão</option>
                  <option value="pix">Pix</option>
                </select>
              </div>
              {state.type === 'card' && (
                <div className="col-sm-3">
                  <label>Bandeira</label>
                  <select value={state.idCard} onChange={(e) => onChange('idCard', e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value={0}>Selecione</option>
                    {cards.map((card) => {
                      return (
                        <option key={card.id} value={card.id}>{card.name}</option>
                      )
                    })}
                  </select>
                </div>
              )}

              <div className="col-sm-2">
                <label>Valor</label>
                <input type="text" onChange={(e) => onChange('pricing', mascaraMoeda(e.target.value))} value={maskCurrency(state.pricing)} className="form-control form-control-sm" name="" id="" />
              </div>
              {state.type === 'money' && (
                <>
                  <div className="col-sm-2">
                    <label htmlFor="">Pago</label>
                    <input type="text" id='paymentPricing' onChange={(e) => onChange('pricingPayment', mascaraMoeda(e.target.value))} value={maskCurrency(state.pricingPayment)} className="form-control form-control-sm" name="" />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="">Troco</label>
                    <input type="text" id='pricingPayment' disabled onChange={(e) => onChange('pricingChange', mascaraMoeda(e.target.value))} value={maskCurrency(state.pricingPayment <= 0 ? 0 : state.pricingPayment - state.pricing)} className="form-control form-control-sm" name="" />
                  </div>
                </>
              )}
              <div className="col-sm-3">
                <button className="mt-4 btn btn-sm btn-success" onClick={async () => {
                  if (!isAccess('pedidos', 'update')) {
                    return Toast.warning('Você não tem permissão para atualizar pedido')
                  }

                  if (state.pricing > order.total) {
                    return Toast.warning('O valor pago não pode ser maior que o total')
                  }

                  const data = { ...state, id: v4(), pricingChange: state.type !== 'money' ? 0 : state.pricingPayment - state.pricing }

                  if (data.type === 'card' && !data.idCard) {
                    return Toast.warning('Selecione por favor a bandeira do cartão')
                  }

                  const orderUpdated = { payments: [...(order?.payments ?? []), data] }

                  if (order.total <= 0) {
                    return Toast.warning('O pedido está com valor zerado')
                  }

                  if (pricingRestante <= 0) {
                    return Toast.warning('O valor total já foi pago')
                  }

                  valuePricing = order.total - orderUpdated.payments.reduce((prev, current) => prev + current.pricing, 0)

                  await updated(order.id, 'payments', orderUpdated.payments, false, true)

                  dispatch(UPDATE_SELECT_ORDER({
                    ...orderUpdated,
                    id: order.id
                  }))

                  setState({
                    id: v4(),
                    type: 'money',
                    idCard: null,
                    pricing: valuePricing,
                    pricingChange: 0,
                    pricingPayment: 0,
                  })
                }}>Adicionar</button>
              </div>
            </div>
            <div className="row">
              <div className="mt-2" >
                {order?.payments?.map(payment => {
                  return (
                    <div
                      key={payment.id}
                      style={{
                        display: 'flex', justifyContent: "space-between", alignItems: 'center',
                        borderBottom: '1px solid #000', paddingTop: 10,
                        paddingBottom: 10
                      }}>
                      <p style={{ fontWeight: 'bold' }}>{PaymentMethod[payment.type]}</p>
                      <p>{maskCurrency(payment.pricing || 0)}</p>
                      <p className="pointer" onClick={async () => {
                        if (!isAccess('pedidos', 'update')) {
                          return Toast.warning('Você não tem permissão para atualizar pedido')
                        }
                        const confirm = await Toast.confirm()
                        if (confirm) {
                          const orderUpdated = { payments: order?.payments?.filter(e => e.id !== payment.id) }
                          await updated(order.id, 'payments', orderUpdated.payments, false, true)

                          dispatch(UPDATE_SELECT_ORDER(orderUpdated))
                        }

                      }}>
                        <MdRemoveCircle />
                      </p>
                    </div>
                  )
                })}
              </div>

              <div className="col-sm-12" style={{ padding: 10, borderRadius: 5, fontWeight: 'bold', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Restante:</p>
                  <p>{maskCurrency(pricingRestante)}</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Total:</p>
                  <p>{maskCurrency(order.total)}</p>
                </div>
                {!['cancelado', 'finalizado'].includes(order.status) && (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <button style={{ float: 'left' }} className="mt-3 btn btn-outline-primary" onClick={() => closeOrCancelOrder()}>Lançar e finalizar pedido</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}