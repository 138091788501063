import { useState } from 'react'
import useSWR from "swr"

import { GlobalContext } from "../context/GlobalContext";
import { api } from '../api/api';
import { ModalOrders } from '../components/Modals/Orders';

let open = false
let modal = null
export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [userPermissions, setUserPermissions] = useState(null)

  function isAccess (key = '', value = '') {
    if (user.typeUser === 'admin') {
      return 'admin'
    }

    if (user.typeUser === 'garcom') {
      return 'garcom'
    }

    if (key || value) {
      return userPermissions ? userPermissions[key]?.includes(value) : false
    }

    return false
  }


  useSWR('/companies', async () => {
    if (window.location.pathname === '/' || window.location.pathname === '/register') {
      return
    }
    const response = await api.get('/companies')

    const dataUser = localStorage.getItem('user')

    const aux = {
      ...JSON.parse(dataUser),
      plan: response.data.plan,
      update: new Date().toISOString()
    }

    localStorage.setItem('user', JSON.stringify(aux))

    setUser(aux)

    return
  }, { refreshInterval: 60000, refreshWhenHidden: true })


  function openModalOrder () {
    if (!modal)
      modal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {
    })

    if (open) {
      modal.hide()
    }

    if (open === false) {
      modal.show()
    }
  }

  return (
    <GlobalContext.Provider value={{ user, setUser, isAccess, userPermissions, setUserPermissions, openModalOrder }}>
      {children}
    </GlobalContext.Provider>
  )
}