import { useContext, useState } from "react";
import { api } from "../../../../api/api";
import useSWR from "swr"
import { mascaraMoeda, maskCurrency } from "../../../../utils/utils";
import { v4 } from "uuid";
import { ListProducts } from "./ListProducts";
import { Toast } from "../../../../utils/Toast";
import { OrderContext } from "../../../../context/OrderContext";
import { UPDATE_ORDER } from "../../../../stores/reducers/OrderReducers";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { FaMinus, FaPlus } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { sendPrint } from "../../../../reporting/print";

export function AddProduct ({ order, setVisibleAddProduct, update, setValueProduct }) {
  const { calculateTotal } = useContext(OrderContext)
  const dispatch = useDispatch()
  const [categorySelect, setCategorySelect] = useState('sem-categoria')
  const [filterProduct, setFilterProduct] = useState('')
  const [confirmationProducts, setConfirmationProducts] = useState(false)
  const [productSelect, setProductSelect] = useState(null)
  const [listProductsAdd, setListProductsAdd] = useState([])

  const [selects, setSelects] = useState({
    idProduct: null,
    nameProduct: '',
    observation: '',
    pricing: 0,
    promotionalPrice: 0,
    quantity: 0,
    additinal: {},
    idSector: null
  })

  const { data: categories } = useSWR('/category', async () => {
    const response = await api.get('/category')
    return response.data
  }, { refreshInterval: 15000, refreshWhenHidden: true })

  const { data: products } = useSWR('/products', async () => {
    const response = await api.get('/products')
    return response.data.data
  }, { refreshInterval: 15000, refreshWhenHidden: true })

  const { data: categoriesAdditinal } = useSWR(['/category/additional/products/', productSelect?.id], async (productId) => {
    if (!productId || productId.length === 0 || !productId[1]) {
      return []
    }
    const response = await api.get(`/category/additional/products/${productId[1]}`)
    return response.data
  })

  function plusOrLessQuantityAdditional (additional, categoryAdditionalName, quantity, category) {
    const max = category.max === 0 ? Infinity : category.max
    const aux = { ...selects }
    const _name = categoryAdditionalName.replace(/ /g, '_')

    if (!aux.additinal[_name]) {
      aux.additinal[_name] = []
    }

    const restantAdditional = aux.additinal[_name].filter(e => e.id !== additional.id)
    const actual = aux.additinal[_name].find(e => e.id === additional.id)

    if (aux.additinal[_name].reduce((prev, current) => prev + current.quantity, 0) >= max && quantity >= 1) {
      return
    }

    if (aux.additinal[_name].length >= max && quantity >= 1) {
      return
    }

    if (!actual) {
      aux.additinal[_name].push({
        id: additional.id,
        name: additional.name,
        pricing: parseFloat(additional.pricing),
        quantity: quantity < 0 ? 0 : quantity,
      })
    } else {
      const _quantity = (actual?.quantity ?? 0) + quantity

      aux.additinal[_name] = [
        ...restantAdditional,
      ]

      if (_quantity > 0 && actual) {
        aux.additinal[_name].push({ ...actual, quantity: _quantity < 0 ? 0 : _quantity })
      }
    }

    setSelects(aux)
  }

  async function addProduct (dataProducts = [], { print }) {
    if (!Array.isArray(dataProducts) || dataProducts.length === 0) {
      return;
    }

    dataProducts = dataProducts.map((v) => {
      return {
        ...v,
        isPrinted: false,
        isSeen: false
      }
    })

    const newProducts = [...order?.productsOrders ?? [], ...dataProducts]

    const newOrder = {
      id: order.id,
      productsOrders: newProducts
    }

    const totalUpdated = calculateTotal(newOrder)

    const payload = {
      ...newOrder,
      total: totalUpdated
    }

    dispatch(UPDATE_ORDER(payload))
    const next = await update(false, true, payload)

    setVisibleAddProduct(false)

    // se não for continuar pq deu erro em algo pra que vou imprimir essa buceta?
    if (next) {
      if (print) {
        await sendPrint('product-order', { dataProducts }, order.id)
      }
    }

    setListProductsAdd([])
  }

  function clearSelect () {
    setSelects({
      idOrder: null,
      idProduct: -1,
      pricing: 0,
      quantity: 1,
      additinal: {},
      observation: '',
      idSector: null
    })
  }

  return (
    <>
      <div style={{ marginLeft: 10 }}>
        {!confirmationProducts && (
          <>
            {!productSelect && (
              <div className="ms-1 mb-2">
                <select onChange={(e) => setCategorySelect(e.target.value)} value={categorySelect} className="mt-2 form-select">
                  {
                    _.orderBy(categories, 'name', 'asc')
                      ?.concat({ name: 'Sem categoria', id: 'sem-categoria', color: 'bg-dark', active: true })
                      ?.filter(e => Boolean(e.active))
                      ?.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                  }
                </select>
              </div>
            )}

            {!productSelect && (
              <>
                <hr />
                <input type="text" value={filterProduct} onChange={(x) => setFilterProduct(x.target.value)} placeholder="Digite o nome do produto" className="ms-1 me-1 form-control form-control-sm " />
                <div className="mt-2 card-pai-product" style={{ overflow: 'auto', height: 340, padding: 10, borderRadius: 5, display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", flex: 1, gap: 10 }}>
                  {products?.filter(e => {
                    if (!Boolean(e.active)) {
                      return false
                    }

                    if ((e.categoryId === null ? 'sem-categoria' : e.categoryId) === categorySelect) {
                      return true
                    }

                    return false
                  }).filter(e => e.name.toLowerCase().includes(filterProduct.toLowerCase())).map((e => {
                    return (
                      <div key={e.id} className="card pointer card-product" onClick={() => {
                        setProductSelect(e)
                        setFilterProduct('')
                        setSelects(state => {
                          return {
                            ...state,
                            idProduct: e?.id,
                            nameProduct: e?.name,
                            pricing: e?.pricing,
                            promotionalPrice: e.promotionalPrice,
                            quantity: 1,
                            idSector: e.idSector
                          }
                        })
                      }}>
                        <img loading="lazy" width={100} height={100} src={e.image || 'https://pedidos-images.s3.amazonaws.com/default/image-default.png'} className="card-img-top"
                          alt="..." />
                        <div className="card-body">
                          <p className="card-title">{e.name.charAt(0).toUpperCase() + e.name.slice(1)}</p>
                        </div>
                      </div>
                    )
                  }))}
                </div>

              </>
            )}

            {productSelect && (
              <>
                <hr />
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                  <h4 >{productSelect.name.charAt(0).toUpperCase() + productSelect.name.slice(1)}</h4>
                  <button className="btn btn-sm btn-primary" onClick={() => setProductSelect(null)}>Voltar</button>
                </div>
                <div className="col-sm-12 mt-2 row" style={{ overflow: 'auto', maxHeight: 250 }}>
                  {categoriesAdditinal?.sort((a, b) => a.assortment - b.assortment)?.map((item) => {
                    const nameCategory = item.name.split(' ').join('_')
                    return (
                      <div key={item.id}>
                        <ul className="list-group mt-3 mb-3">
                          <li
                            className="list-group-item active bg-white border text-gray fw-bold d-flex align-items-center justify-content-between"
                            style={{ border: 'none', }} aria-current="true">
                            <span>{nameCategory.replace(/_/ig, ' ')}</span>
                            <span className="fw-bold"><i>min</i> ({item.min}) - <i>max</i> ({item.max})</span>
                          </li>
                          {item.additionals.map((value) => {
                            const additionalSelect = selects.additinal[nameCategory]?.find(e => e.id === value.id)
                            return (
                              <li className="list-group-item d-flex align-items-center justify-content-between" key={value.id}>
                                <p className="fw-border">{value.name} - <strong>{maskCurrency(value.pricing)}</strong></p>
                                <p>
                                  <span className={`fw-bold ${isMobile ? 'fs-2' : 'fs-5'} me-2 pointer`} onClick={() => plusOrLessQuantityAdditional(value, item.name, -2, item)}>
                                    <FaMinus />
                                  </span>
                                  <span className={`${isMobile ? 'fs-2' : 'fs-5'}`} style={{ marginLeft: 10, marginRight: 10 }}>{additionalSelect?.quantity || 0}</span>
                                  <span className={`fw-bold ${isMobile ? 'fs-2' : 'fs-5'} ms-2 pointer`} onClick={() => plusOrLessQuantityAdditional(value, item.name, 1, item)}>
                                    <FaPlus />
                                  </span>
                                </p>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })}
                </div>
                <div className="row">
                  {productSelect && (
                    <>
                      <div className="col-sm-6">
                        <label htmlFor="">Qtd</label>
                        <input type="number" value={selects?.quantity ?? 0} className="form-control form-control-sm" onChange={(e) => {
                          setSelects(state => {
                            return {
                              ...state,
                              quantity: e.target.value ?? 1
                            }
                          })
                        }} />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="">Valor</label>
                        <input type="text" value={maskCurrency((selects.promotionalPrice || selects?.pricing) ?? 0)} className="form-control form-control-sm" onChange={(e) => {
                          setSelects(state => {
                            return {
                              ...state,
                              pricing: mascaraMoeda(e.target.value ?? '0')
                            }
                          })
                        }} />
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="">Descrição</label>
                        <input type="text" value={selects?.observation} className="form-control form-control-sm" onChange={(e) => {
                          setSelects(state => {
                            return {
                              ...state,
                              observation: e.target.value
                            }
                          })
                        }} />
                      </div>
                      <div className="col-sm-12" style={{ textAlign: 'right' }}>
                        <button onClick={async () => {
                          clearSelect()
                          const productCreated = {
                            id: v4(),
                            idOrder: order.id,
                            idProduct: selects.idProduct,
                            quantity: selects.quantity,
                            pricing: selects.promotionalPrice || selects.pricing,
                            observation: selects.observation,
                            additinal: selects.additinal,
                            name: selects.nameProduct,
                            idSector: selects?.idSector ?? null
                          }

                          setListProductsAdd([...listProductsAdd, productCreated])

                          setProductSelect('')
                          setConfirmationProducts(true)
                        }} className="btn btn-sm btn-success mt-4" >
                          Adicionar produto
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {(confirmationProducts && listProductsAdd.length > 0) && (
          <div className="mt-3">
            <hr />
            <h3 className="text-center">Lista de produtos</h3>
            <ListProducts products={listProductsAdd} removeProduct={async (id) => {
              const confirm = await Toast.confirm()

              if (confirm) {
                setListProductsAdd(listProductsAdd.filter((e) => e.id !== id))
              }
            }} />

            <div className="mt-5" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <button className="btn btn-sm btn-outline-primary ms-3" onClick={() => addProduct(listProductsAdd, { print: true })}>
                Confirmar
              </button>
              <button className="btn btn-sm btn-dark ms-3" onClick={() => setConfirmationProducts(false)}>
                Voltar e adicionar mais produtos
              </button>
            </div>
          </div>
        )}
      </div>

    </>
  )
}