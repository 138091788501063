import { useEffect, useState } from "react"

import { api } from '../../../../api/api'
import { Toast } from "../../../../utils/Toast"

const DEFAULT_HOURS = [
  {
    id: null,
    name: "Domingo",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 1
  },
  {
    id: null,
    name: "Segunda-feira",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 2
  },
  {
    id: null,
    name: "Terça-feira",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 3
  },
  {
    id: null,
    name: "Quarta-feira",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 4
  },
  {
    id: null,
    name: "Quinta-feira",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 5
  },
  {
    id: null,
    name: "Sexta-feira",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 6
  },
  {
    id: null,
    name: "Sábado",
    startHour: "",
    endHour: "",
    checked: false,
    assortment: 7
  }
]

export function Schedules() {
  const [days, setDays] = useState({
    turno1: DEFAULT_HOURS,
    turno2: DEFAULT_HOURS,
    turno3: DEFAULT_HOURS,
  })

  function onChangeDays(turno, nameDay, key, valueInput) {
    const filter = days[turno]?.map((e) => {
      if (e.name === nameDay) {
        return { ...e, [key]: valueInput }
      }
      return e
    })

    if (filter)
      setDays({
        ...days,
        [turno]: filter,
      })
  }

  async function loadDataScheduling() {
    const response = await api.get('/schedules/business_hours')
    const aux = response.data.map((value) => {
      return {
        id: value.id,
        name: value.days,
        startHour: value.startHour,
        endHour: value.endHour,
        checked: value.active,
        assortment: value.assortment,
        turno: value.turno
      }
    })
    if (aux.length > 0) {
      setDays({
        turno1: aux.filter(e => e.turno === 'turno1').length === 0 ? DEFAULT_HOURS : aux.filter(e => e.turno === 'turno1'),
        turno2: aux.filter(e => e.turno === 'turno2').length === 0 ? DEFAULT_HOURS : aux.filter(e => e.turno === 'turno2'),
        turno3: aux.filter(e => e.turno === 'turno3').length === 0 ? DEFAULT_HOURS : aux.filter(e => e.turno === 'turno3'),
      })
    }
  }

  async function create() {
    for (const turno in days) {
      await Promise.all(days[turno].map((item) => {
        if (item.id) {
          return api.put('/schedules/' + item.id, {
            days: item.name,
            startHour: item.startHour,
            endHour: item.endHour,
            active: item.checked,
            assortment: item.assortment,
            turno: turno
          })
        } else {
          return api.post('/schedules', {
            days: item.name,
            startHour: item.startHour,
            endHour: item.endHour,
            active: item.checked,
            type: 'business_hours',
            assortment: item.assortment,
            turno: turno
          })
        }
      }))
    }

    Toast.success("Atualizado com sucesso")
  }

  useEffect(() => {
    loadDataScheduling()
  }, [])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', gap: 10, width: '100%' }}>
        <div className="card p-3 mt-2 w-100">
          <h4 className="mt-3 text-center">Turno 1</h4>
          <button className="btn btn-outline-primary" onClick={() => {
            setDays({
              ...days,
              turno1: days.turno1.map(e => {
                return {
                  id: e.id,
                  name: e.name,
                  startHour: "",
                  endHour: "",
                  checked: false,
                  assortment: e.assortment
                }
              })
            })
          }}>Limpar Horários</button>
          <div>
            {days?.turno1?.map((day, index) => {
              return (
                <div className="row" key={index} style={{ alignItems: 'center' }}>
                  <div className="col-sm-3 form-check mt-4 ms-2">
                    <input className="form-check-input" checked={day.checked} onChange={(event) => onChangeDays('turno1', day.name, 'checked', event.target.checked)} type="checkbox" id={`${day.name}-turno1`} />
                    <label className="form-check-label" htmlFor={`${day.name}-turno1`}>
                      {day.name}
                    </label>
                  </div>
                  <div className="col-sm-9 row" style={{}}>
                    <div className="col-sm-6">
                      <label htmlFor="">Inicio</label>
                      <input type="time" value={day.startHour} onChange={(e) => onChangeDays('turno1', day.name, 'startHour', e.target.value)} className="form-control form-control-sm" />
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="">Fim</label>
                      <input type="time" value={day.endHour} onChange={(e) => onChangeDays('turno1', day.name, 'endHour', e.target.value)} className="form-control form-control-sm" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="card p-3 mt-2 w-100">
          <h4 className="mt-3 text-center">Turno 2</h4>
          <button className="btn btn-outline-primary" onClick={() => {
            setDays({
              ...days,
              turno2: days.turno2.map(e => {
                return {
                  id: e.id,
                  name: e.name,
                  startHour: "",
                  endHour: "",
                  checked: false,
                  assortment: e.assortment
                }
              })
            })
          }}>Limpar Horários</button>
          <div>
            {days?.turno2?.map((day, index) => {
              return (
                <div className="row" key={index} style={{ alignItems: 'center' }}>
                  <div className="col-sm-3 form-check mt-4 ms-2">
                    <input className="form-check-input" checked={day.checked} onChange={(event) => onChangeDays('turno2', day.name, 'checked', event.target.checked)} type="checkbox" id={`${day.name}-turno2`} />
                    <label className="form-check-label" htmlFor={`${day.name}-turno2`}>
                      {day.name}
                    </label>
                  </div>
                  <div className="col-sm-9 row" style={{}}>
                    <div className="col-sm-6">
                      <label htmlFor="">Inicio</label>
                      <input type="time" value={day.startHour} onChange={(e) => onChangeDays('turno2', day.name, 'startHour', e.target.value)} className="form-control form-control-sm" />
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="">Fim</label>
                      <input type="time" value={day.endHour} onChange={(e) => onChangeDays('turno2', day.name, 'endHour', e.target.value)} className="form-control form-control-sm" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="card p-3 mt-2 w-100">
          <h4 className="mt-3 text-center">Turno 3</h4>
          <button className="btn btn-outline-primary" onClick={() => {
            setDays({
              ...days,
              turno3: days.turno3.map(e => {
                return {
                  id: e.id,
                  name: e.name,
                  startHour: "",
                  endHour: "",
                  checked: false,
                  assortment: e.assortment
                }
              })
            })
          }}>Limpar Horários</button>
          <div>
            {days?.turno3?.map((day, index) => {
              return (
                <div className="row" key={index} style={{ alignItems: 'center' }}>
                  <div className="col-sm-3 form-check mt-4 ms-2">
                    <input className="form-check-input" checked={day.checked} onChange={(event) => onChangeDays('turno3', day.name, 'checked', event.target.checked)} type="checkbox" id={`${day.name}-turno3`} />
                    <label className="form-check-label" htmlFor={`${day.name}-turno3`}>
                      {day.name}
                    </label>
                  </div>
                  <div className="col-sm-9 row" style={{}}>
                    <div className="col-sm-6">
                      <label htmlFor="">Inicio</label>
                      <input type="time" value={day.startHour} onChange={(e) => onChangeDays('turno3', day.name, 'startHour', e.target.value)} className="form-control form-control-sm" />
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="">Fim</label>
                      <input type="time" value={day.endHour} onChange={(e) => onChangeDays('turno3', day.name, 'endHour', e.target.value)} className="form-control form-control-sm" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="divider mt-2 mb-4"></div>
      <div className="text-start">
        <button className="btn btn-dark-blue" onClick={create}>Salvar Alterações</button>
      </div>
    </>
  )
}