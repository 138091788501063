import { DateTime } from "luxon"
import { useSWRConfig } from "swr"

import { maskCurrency } from "../../../utils/utils"
import { SiIfood } from "react-icons/si";
import { FaCheck, FaEdit } from "react-icons/fa";
import { BsCash } from "react-icons/bs";
import { api } from '../../../api/api'
import { Toast } from "../../../utils/Toast";
import { useCompany } from "../../../hooks/useCompany";
import { sendPrintNota } from "../../../reporting/print";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";

export const ENUM_PAYMENT = {
  'CASH': 'Dinheiro',
  'DEBIT': 'Débito',
  'CREDIT': 'Crédito',
  'MEAL_VOUCHER': 'Vale alimentação',
  'FOOD_VOUCHER': 'vale refeição',
  'GIFT_CARD': 'Gift Card',
  'DIGITAL_WALLET': 'Pagamento online',
  'PIX': 'Pix',
  'OTHER': 'Outros'
}

const ENUM_TARGET_CUPON = {
  "CART": "Carrinho",
  "DELIVERY_FEE": "Frete grátis",
  "ITEM": "Desconto no item",
}

const ENUM_SPONSOR_SHIPVALUES = {
  "IFOOD": "Incentivo do iFood",
  "MERCHANT": "Incentivo da Loja",
  "EXTERNAL": "Incentivo da Indústria"
}

let isSendCupom = false

export function ModalOrderIfood ({ order, clear }) {
  const { user } = useContext(GlobalContext)
  const { configurations } = useCompany()
  const { mutate } = useSWRConfig()

  const pedido = order?.payload?.length > 0 ? order.payload[0] : {}

  if (!pedido?.id) {
    return <></>
  }

  async function getCancellationReasons () {
    const response = await api.get('/ifood/cancellation-reasons/' + pedido.id)

    if (response.data.length === 0) {
      return Toast.warning('Não pode cancelar o pedido.')
    }

    const options = {}
    for (const item of response.data) {
      options[item.cancelCodeId] = item.description
    }

    const result = await Toast.selectValue('Selecione um dos motivos', '', options)
    if (result === undefined) {
      return;
    }

    await api.post('/ifood/request-cancellation/' + pedido.id, {
      reason: response.data.find(x => x.cancelCodeId === result).description,
      cancelCodeId: result
    })
  }

  async function acceptedCancellation () {
    const { isConfirmed, isDenied, isDismissed } = await Toast.acceptedOrNoCancelleationOrder('Você confirma o cancelamento do pedido?', "Não aceita", "Aceita", true)

    if (isDismissed) {
      return;
    }

    if (isConfirmed) {
      await api.post('/ifood/accept-cancellation/' + pedido.id)
    }

    if (isDenied) {
      await api.post('/ifood/deny-cancellation/' + pedido.id)
    }
  }

  async function printIfoodOrder () {
    await api.get('/ifood/print?id=' + order.id)

    Toast.success('Pedido está sendo impresso')
  }

  async function infoFiscal (idProductIfood) {
    const handleNCM = async () => {
      const { data } = await api.get('/ncms')

      const options = data.reduce((acc, obj) => {
        acc[obj.code] = obj.code;
        return acc;
      }, {});

      return await Toast.selectValue('Selecione o NCM', '', options)
    }

    const handleCest = async () => {
      const { data } = await api.get('/cests')

      const options = data.reduce((acc, obj) => {
        acc[obj.code] = obj.code;
        return acc;
      }, {});

      return await Toast.selectValue('Selecione o Cest', '', options)
    }

    const handleRefFiscal = async () => {
      const { data } = await api.get('/products/list-refs')

      const options = data.reduce((acc, obj) => {
        acc[obj.ref] = `${obj.ref} - ${obj.description}`;
        return acc;
      }, {});

      return await Toast.selectValue('Selecione o código fiscal', '', options)
    }

    const ncm = await handleNCM()
    if (!ncm) { return; }
    const cest = await handleCest()
    const refFiscal = await handleRefFiscal()
    if (!refFiscal) { return; }

    await api.post('/ifood/product/fiscal', {
      ncm,
      cest,
      refFiscal,
      idProduct: idProductIfood
    })

    Toast.success('Informações fiscais de produto vinculadas')
  }


  async function sendCupom (idOrder) {
    try {
      if (user.plan !== 'complet') {
        return Toast.warning('Apenas plano Completo pode emitir cupom fiscal.')
      }

      if (isSendCupom) {
        return Toast.warning('Aguarde estamos emitindo o cupom.')
      }

      Toast.success("Estamos emitindo o cupom.")

      isSendCupom = true

      const { data } = await api.post('/nfce', { idOrder, isIfood: true })

      isSendCupom = false

      if (!configurations.defaultNamePrint || !configurations.defaultCodePrint) {
        window.open(data.dataWebMania.danfe)
      } else {
        sendPrintNota(data.dataWebMania.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)
      }

      document.getElementById('modal-close-ifood').click()
      await mutate('/ifood/orders')
    } catch (error) {
      isSendCupom = false
    }
  }

  const isCard = ['CREDIT', 'DEBIT'].includes(pedido?.payments?.methods[0]?.method) ?
    `- ${pedido.payments.methods[0]?.card?.brand.charAt(0).toUpperCase() + pedido.payments.methods[0]?.card?.brand.slice(1)}` :
    ''

  const isCanceled = order?.payload?.find(e => e.fullCode === 'CANCELLED')

  const isTest = pedido.isTest
  return (
    <>
      <div className="modal fade" id="modalOrderIfood" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalOrderIfoodLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalOrderIfoodLabel"><SiIfood size={20} color="red" /> Pedido {isTest && ' - Pedido de teste não fazer'}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id="modal-close-ifood" onClick={() => clear()} aria-label="Close"></button>
            </div>
            <div id="modal-order-ifood" className="modal-body" style={{ height: 600, overflow: 'auto' }}>
              {isCanceled && (
                <div style={{ backgroundColor: 'red', padding: 5, borderRadius: 5, color: 'white', fontWeight: 'bold', marginBottom: 10 }}>
                  <p>Cancelado por {{ 'RESTAURANT': 'Restaurante', 'IFOOD': 'Ifood', 'CONSUMER': 'Cliente' }[isCanceled.metadata.CANCEL_ORIGIN]}</p>
                  <p>Motivo: {isCanceled.metadata.CANCEL_REASON}</p>
                </div>
              )}

              <h6 style={{ backgroundColor: '#717171', padding: 10, borderRadius: 3, color: 'white' }}>{pedido.customer.name}</h6>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontWeight: 'bold' }}> Pedido: #{pedido.displayId}</p>
                <p>Feito ás: {DateTime.fromISO(pedido.createdAt).toFormat('HH:mm')}</p>
                {pedido?.delivery?.pickupCode && <p style={{ fontWeight: 'bold' }}>Código de coleta: {pedido?.delivery?.pickupCode}</p>}
                {pedido?.orderTiming === 'IMMEDIATE' && (
                  <p>Entrega prevista: {DateTime.fromISO(pedido.delivery.deliveryDateTime).toFormat('HH:mm')}</p>
                )}
                {pedido?.orderTiming === 'SCHEDULED' && (
                  <p>Entrega prevista: <b>Inicio:</b> {DateTime.fromISO(pedido?.schedule?.deliveryDateTimeStart).toFormat('dd/MM HH:mm')} <b>Data de Entrega:</b> {DateTime.fromISO(pedido?.schedule?.deliveryDateTimeEnd).toFormat('dd/MM HH:mm')}</p>
                )}
              </div>
              {pedido?.delivery && (
                <div className="mt-2 mb-2">
                  <p><b>Endereço:</b> {pedido?.delivery?.deliveryAddress?.formattedAddress}, {pedido?.delivery?.deliveryAddress?.neighborhood} - {pedido?.delivery?.deliveryAddress?.city}</p>
                  <p><b>Complemento:</b> {pedido?.delivery?.deliveryAddress?.complement}</p>
                </div>
              )}

              {order?.statusIfood === 'HANDSHAKE_DISPUTE' && (
                <div style={{ marginTop: 10, border: '1px solid #dcdcdc', padding: 10 }}>
                  <p>Motivo:{order?.payload?.find(e => e.fullCode === 'HANDSHAKE_DISPUTE').metadata.message}</p>
                  <button className="btn btn-outline-danger" onClick={() => acceptedCancellation()}>Aceita cancelamento</button>
                </div>
              )}
              <hr />
              <div>
                <h5>Itens do pedido</h5>
                <div style={{ width: '100%', fontWeight: '600', color: '#666' }}>
                  {pedido?.items?.map(item => {
                    return (
                      <div style={{}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p> {item.quantity}x {item.name}</p>
                          <div style={{ display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <p>{maskCurrency(item.totalPrice)}</p>
                            {!item.infoFiscal && (
                              <p style={{ color: '#6464c9' }} className="pointer" onClick={() => infoFiscal(item.id)}>
                                <FaEdit size={20} />
                              </p>
                            )}

                            {item.infoFiscal && (
                              <p style={{ color: 'red' }} className="pointer" onClick={() => infoFiscal(item.id)}>
                                <FaEdit size={20} />
                              </p>
                            )}
                          </div>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                          {item?.options?.map(e => {
                            return (
                              <div style={{ marginLeft: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p>- {e.quantity}x {e.name}</p>
                                <p>{maskCurrency(e.price)}</p>
                              </div>
                            )
                          })}
                        </div>
                        {item.observations && (
                          <div style={{ backgroundColor: 'yellow', padding: 3, borderRadius: 5, marginBottom: 5 }}>
                            <p>{item.observations}</p>
                          </div>
                        )}

                      </div>
                    )
                  })}
                </div>
              </div>
              <hr />
              <div style={{ fontWeight: '600', color: '#666' }}>
                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Taxa de entrega: </p>
                  <p> {maskCurrency(pedido.total.deliveryFee)}</p>
                </div>

                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Taxa de serviço: </p>
                  <p> {maskCurrency(pedido.total.additionalFees)}</p>
                </div>

                {pedido.benefits && (
                  <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>Descontos: </p>
                    <p> {maskCurrency(pedido.benefits.map(e => e.value).reduce((prev, current) => (prev + current)), 0)}</p>
                  </div>
                )}

                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p>Subtotal: </p>
                  <p> {maskCurrency(pedido.total.orderAmount)}</p>
                </div>
              </div>

              {pedido.benefits && (
                <>
                  <hr />
                  <h5>Cupons de desconto</h5>
                  {pedido.benefits.map(cupon => {
                    return (
                      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <p>Tipo: {ENUM_TARGET_CUPON[cupon.target]}</p>
                          <p>Valor: {maskCurrency(cupon.value)}</p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          {cupon.sponsorshipValues.map(e => <p>{ENUM_SPONSOR_SHIPVALUES[e.name]} - {maskCurrency(e.value)}</p>)}
                        </div>
                      </div>
                    )
                  })}
                </>
              )}

              <hr />

              <div style={{ fontWeight: '600', color: '#666' }}>
                {pedido.payments.prepaid > 0 && (
                  <>
                    <div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'left', gap: 10, alignItems: 'center' }}>
                        <SiIfood size={20} color="red" />
                        <div style={{ width: '100%' }}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>Pago via iFood {isCard}</p>
                            <p>{maskCurrency(pedido.payments.prepaid)}</p>
                          </div>
                          <span style={{ color: '#717171', fontWeight: 'normal' }}>O iFood já recebeu este valor e vamos repassar a sua loja</span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'left', gap: 10, alignItems: 'center' }}>
                      <FaCheck size={20} />
                      <h6>Pago via iFood, não precisa cobrar na entrega</h6>
                    </div>
                  </>
                )}

                {pedido.payments.pending > 0 && (
                  <>
                    <div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'left', gap: 10, alignItems: 'center' }}>
                        <SiIfood size={20} color="red" />
                        <div style={{ width: '100%' }}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p>Cobrar do cliente - {ENUM_PAYMENT[pedido.payments.methods[0].method]}</p>
                            <p>{pedido?.payments?.pending}</p>
                          </div>
                          <span style={{ color: '#717171', fontWeight: 'normal' }}>O entregador deve cobrar este valor no ato da entrega.</span>
                        </div>
                      </div>


                      {pedido.payments.methods[0]?.cash?.changeFor > 0 && (
                        <>
                          <hr />
                          <div>
                            <p><BsCash size={20} /> Valor a receber em dinheiro: {maskCurrency(pedido?.payments?.methods[0]?.cash?.changeFor)}</p>
                          </div>
                        </>
                      )}

                    </div>
                    {pedido.payments.pending === 0 && (
                      <>
                        <hr />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'left', gap: 10, alignItems: 'center' }}>
                          <FaCheck size={20} />
                          <h6>Pago via iFood, não precisa cobrar na entrega</h6>
                        </div>
                      </>
                    )}

                  </>
                )}
              </div>

              {pedido.customer.documentNumber && (
                <>
                  <hr />
                  <p style={{ color: '#717171' }}>Incluir CPF na nota fiscal {pedido.customer.documentNumber}</p>
                </>
              )}
            </div>

            <div style={{ borderTop: '2px solid #dcdcdc' }}>
              {!isCanceled && <button className="btn btn-sm btn-outline-danger mt-2 mb-2 ms-2" onClick={() => getCancellationReasons()}>Cancelar pedido</button>}
              <button className="btn btn-sm btn-outline-primary mt-2 mb-2 ms-2" onClick={() => printIfoodOrder()}>Imprimir pedido</button>
              {!order.nota && (
                <button className="btn btn-sm btn-outline-dark mt-2 mb-2 ms-2" onClick={() => sendCupom(order.idOrder)}>Emitir cupom fiscal</button>
              )}

              {order.nota && (
                <button className="btn btn-sm btn-outline-success mt-2 mb-2 ms-2" onClick={() => sendPrintNota(order.nota.response.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)}>Imprimir cupom fiscal</button>
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  )
}