import { DateTime } from "luxon";
import { FaArrowRight, FaMapMarkerAlt } from "react-icons/fa";
import { IoMdOpen } from "react-icons/io";
import { SiIfood } from "react-icons/si";
import { useSWRConfig } from "swr"
import { ENUM_PAYMENT } from "../../../components/Modals/OrderIfood";

export function CardIfood ({ id, numOrder, name, address, createdAt, orderType, orderTiming, status, pricing, selectOrder, next, order }) {
  const date = DateTime.fromISO(createdAt).toFormat("dd/MM HH:mm")
  const textMethodDelviery = orderType === 'DELIVERY' ? 'Entrega' : 'Retirada'
  const { mutate } = useSWRConfig()

  const canceled = ['cancelado'].includes(status)
  const isRequestCancel = order?.statusIfood === 'HANDSHAKE_DISPUTE'


  let paymentMethod = ''
  const pedido = order?.payload?.length > 0 ? order.payload[0] : {}
  const isOnline = pedido.payments.methods[0].type === 'ONLINE'

  try {
    paymentMethod = ENUM_PAYMENT[pedido?.payments?.methods[0]?.method]

    if (isOnline) {
      paymentMethod = 'Ifood'
    }

    if (pedido.payments.prepaid > 0) {
      paymentMethod = 'Ifood'
    }
    
  } catch (error) { }

  return (
    <div key={id} className="card mb-3 shadow-sm" style={{ width: '100%', border: '1px solid #c9c8c8', borderLeft: canceled ? '3px solid red' : '3px solid green' }}>
      {isRequestCancel && (
        <div style={{ position: 'absolute', top: -15, right: -10 }}>
          <div className="spinner-grow text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div className="card-body">
        <div>
          <div className="card-text">
            <div className="mb-2" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                <SiIfood size={20} color="red" />
                <p><b>#{numOrder} ({date})</b></p>
              </div>
              <p className="m-0 p-0" style={{ fontSize: '1rem', fontWeight: 600 }}>Valor: {pricing}</p>
              <p className="m-0 p-0" style={{ fontSize: '1rem', fontWeight: 600 }}>{paymentMethod}</p>
            </div>
            <p className="m-0 p-0" style={{ fontSize: '1rem' }}>Cliente: {name}</p>

            <div className="mt-2 p-2 rounded" style={{ backgroundColor: "#f8f7f7" }}>
              <p style={{ color: '' }}><FaMapMarkerAlt color="#666" size={20} /> {textMethodDelviery === 'Retirada' ? 'Retirada no local' : address}</p>
            </div>

            <div className="mt-2">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <button className="btn btn-sm btn-outline-dark">Imprimir <BsPrinter size={20} /></button> */}
                <button className="btn btn-sm btn-outline-primary" onClick={() => {
                  selectOrder()
                }} >Ver pedido <IoMdOpen /></button>
              </div>
              {(next && status !== 'PLACED') && (
                <button className="btn btn-outline-success w-100 mt-2" onClick={async () => {
                  await next()
                  await mutate('/ifood/orders')
                }}>
                  Avançar  <FaArrowRight size={20} />
                </button>
              )}

              {(next && status === 'PLACED') && (
                <>
                  <button className="btn btn-success w-100 mt-2" onClick={async () => {
                    await next()
                    await mutate('/ifood/orders')
                  }}>
                    Aceitar pedido
                  </button>
                  {/* <button className="btn btn-outline-danger w-100 mt-2" onClick={() => refused()}>
                    Recusar pedido
                  </button> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

