import { useState } from "react"
import { HiDocumentReport } from "react-icons/hi"
import useSWR, { useSWRConfig } from "swr"
import { api } from "../../../../api/api"

export function TopSelling () {
  const [filter, setFilter] = useState({})

  const { mutate } = useSWRConfig()

  const { data, isLoading } = useSWR('/product-order/report/top-selling', async () => {
    if (!filter.startDate || !filter.endDate) {
      return []
    }

    const url = `/product-order/report/top-selling?startDate=${filter.startDate}&endDate=${filter.endDate}`
    const response = await api.get(url)

    return response.data
  })

  return (
    <>
      <div>
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>
            <HiDocumentReport color='white' className="mb-1" size={22} />  Listagem de produtos mais vendidos
          </p>
        </div>

        <div className="mt-4" style={{ width: '100%' }}>
          <div className="row border p-1 pb-3" style={{ margin: '0 auto' }}>
            <p style={{ marginTop: 5 }}>Pesquisa</p>
            <div className="col-sm-3">
              <label htmlFor="">Inicio</label>
              <input type="date" onChange={(e) => setFilter({ ...filter, 'startDate': e.target.value })} className="form-control form-control-sm" />
            </div>
            <div className="col-sm-3">
              <label htmlFor="">Fim</label>
              <input type="date" onChange={(e) => setFilter({ ...filter, 'endDate': e.target.value })} className="form-control form-control-sm" />
            </div>
            <div className="col-sm-2">
              <button className="btn btn-sm btn-dark-blue mt-4" onClick={() => mutate('/product-order/report/top-selling')}>Pesquisar</button>
            </div>
          </div>
          <table className="table table-sm" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th >

                </th>
                <th rowSpan={2}>
                  Produto
                </th>
                <th rowSpan={2}>
                  Quantidade
                </th>
              </tr>
            </thead>
            <tbody style={{ width: '100%' }}>
              {!isLoading && (
                <>
                  {data?.map((value) => {
                    return (
                      <tr key={value.id}>
                        <td></td>
                        <td>{value.nameProduct}</td>
                        <td>{value.totalQuantity}</td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
          {isLoading && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 15, alignItems: 'center' }}>
              <h5>Carregando informações  </h5>
              <div className="spinner-border mb-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}